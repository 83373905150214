// Utilities

@mixin ellipsis { white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }
@mixin multi-ellipsis($line) { display: -webkit-box; -webkit-line-clamp: $line; -webkit-box-orient: vertical; overflow: hidden; }
@mixin transition($sec) {
  -webkit-transition: $sec;
  -moz-transition: $sec;
  -ms-transition: $sec;
  -o-transition: $sec;
  transition: $sec;
}

@mixin transform($detail) {
  -webkit-transform: $detail;
  -moz-transform: $detail;
  -ms-transform: $detail;
  -o-transform: $detail;
  transform: $detail;
}
@mixin aspect-ratio($width, $height) {
  position: relative; overflow: hidden;

  &:before { display: block; content: ""; width: 100%; padding-top: ($height / $width) * 100%; }

  img { transform: translate(-50%, -50%); left: 50%; top: 50%; position: absolute;}
}

.overlay { 
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)); position: absolute; top: 0; left: 0;
  width: 100%; height: 100%;
}

.text-center { text-align: center; }

.radii { border-radius: $radii; }
.radii-t { border-top-left-radius: $radii; border-top-right-radius: $radii; }
.radii-b { border-bottom-left-radius: $radii; border-bottom-right-radius: $radii; }
.radii-l { border-top-left-radius: $radii; border-bottom-left-radius: $radii; }
.radii-r { border-top-right-radius: $radii; border-bottom-right-radius: $radii; }

.bg-gray { background: $gray; }
.bg-gray-white { background-image: linear-gradient($white,$gray); }
.bg-abstract {
  background: url('../images/abstract.svg'); background-position: center -5%; background-repeat: no-repeat; background-size: contain;
}
.bg-abstract-color {
  background: url('../images/abstract-color.svg'); background-position: center 116%; background-repeat: no-repeat; background-size: contain;
}

.box-3-1,
.box-16-9,
.box-4-3,
.box-3-4,
.box-1-1 {
  &.fit-width img { width: 100%; height: auto; max-height: initial; }
  &.fit-height img { height: 100%; width: auto; max-width: initial; }
}
.box-3-1 { @include aspect-ratio(3,1); }
.box-16-9 { @include aspect-ratio(16,9); }
.box-4-3 { @include aspect-ratio(4,3); background: $gray; }
.box-3-4 { @include aspect-ratio(3,4); }
.box-1-1 { @include aspect-ratio(1,1); }

.col {
  display: flex;
  flex-direction: column;

  .full {
    flex-grow: 1;
  }
}
.col-12 {
  width: 100%;
}
.col-9 {
  width: 75%;
}
.col-6 {
  width: 50%;
}
.col-4 {
  width: 33.333%;
}
.col-3 {
  width: 25%;
}

.flex {
  display: flex;

  .flex-grow {
    flex-grow: 1;
  }
  .flex-shrink {
    flex-shrink: 0;
  }
  &.flex-column {
    flex-direction: column;
  }
  &.flex-column-reverse {
    flex-direction: column-reverse;
  }
  &.flex-row {
    flex-direction: row;
  }
  &.flex-row-reverse {
    flex-direction: row-reverse;
  }
  &.flex-wrap {
    flex-wrap: wrap;
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-space-around {
    justify-content: space-around;
  }
  &.justify-space-between {
    justify-content: space-between;
  }
  &.align-start {
    align-items: flex-start;
  }
  &.align-end {
    align-items: flex-end;
  }
  &.align-center {
    align-items: center;
  }
  &.align-baseline {
    align-items: baseline;
  }
  &.align-stretch {
    align-items: stretch;
  }
}