a { display: block; }

p, span, a { word-wrap: break-word; }

h1,h2,h3,h4,h5,h6 { font-weight: 500; line-height: 1.25 !important; }
h1 { font-size: 32px; }
h2 { 
  font-size: 28px; display: flex; align-items: center;

  &:before { content: ''; width: 8px; height: 8px; background: $secondary; border-radius: $radii; display: block; margin-right: 1rem; }
}
h3 { font-size: 18px; }

.caption { font-size: 12px; color: $text-secondary; }