.product-card {
  position: relative;

  .rank {
    width: 40px; height: 40px; line-height: 40px; text-align: center; position: absolute; top: 0; right: 16px; z-index: 1; background: $warning;
    border-bottom-left-radius: $radii; border-bottom-right-radius: $radii; font-weight: 500; font-size: 20px;
    color: $white;
  }
}

.top-three { 
  padding: 1.5rem 1rem; background: $gray; 
}

@media (min-width: 600px) {
  .products { 
    // background: $gray;

    .MuiGrid-item { max-width: 20% !important; flex-basis: 20% !important; }
  }
}