@import './_reset';
@import './variable';
@import './_space';
@import './_typography';
@import './utilities';

@import './product';
@import './blog';
@import './plan';

:root {
  font-size: 16px;
}

body {
  font-family: 'Roboto', 'Kanit', sans-serif;
  color: $text-primary;
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
  background: $background-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  &:before {
    content: '';
    width: 100%;
    height: 4px;
    background: $primary;
  }

  .logo {
    background: $primary;
    padding: 1rem;
    border: none;
    outline: none;
    flex-shrink: 0;
  }

  .menu {
    a {
      color: $info;
      font-size: 1rem;
    }
  }

  .my-tool {
    border-color: $info;
    padding-left: 24px;
    padding-right: 24px;
  }

  ol li:last-child {
    color: $primary;
  }
}

.share {
  color: $white !important;
  background: $info !important;
  position: fixed !important;
  bottom: 24px;
  right: 24px;
  z-index: 999;
}

// .mini-footer {
//   background: $primary; height: 4px; width: 100%; position: absolute; bottom: 0; left: 0;
// }

.main-container {
  min-height: 100vh;
}

*[class*='-page'] {
  min-height: calc(100vh - 84px);
}

img {
  display: block;
  max-width: 100%;
  flex-shrink: 0;
}

.hover-card {
  // @include transition(0.2s !important);

  span {
    display: block;
  }

  p,
  h6 {
    @include transition(0.2s);

    &:not(.no-ellipsis) {
      @include ellipsis;
    }
  }

  &:hover {
    p,
    h6 {
      color: $secondary;
    }
  }
}

input {
  font-size: 1rem;
}

.divider {
  width: 100%;
  height: 1px;
  background: $border;
}

.loading-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-share {
  .MuiInputBase-root {
    background: $gray;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    * {
      border: none !important;
      outline: none !important;
    }
  }

  .submit {
    background: $info !important;
    color: $white;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: $radii;
    border-bottom-right-radius: $radii;
  }

  .close {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
.text-captcha {
  font-size: 12px;
  color: $text-secondary;
  margin-top: 8px;

  a {
    display: inline;
    color: $info;
  }
}

.d-block {
  display: block !important;
}
.d-inline-block {
  display: inline-block !important;
}

.d-flex {
  display: flex !important;

  &.flex-column {
    flex-direction: column;
  }
  &.flex-column-reverse {
    flex-direction: column-reverse;
  }
  &.flex-row {
    flex-direction: row;
  }
  &.flex-row-reverse {
    flex-direction: row-reverse;
  }
  &.flex-wrap {
    flex-wrap: wrap;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-space-around {
    justify-content: space-around;
  }
  &.justify-space-between {
    justify-content: space-between;
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.align-start {
    align-items: flex-start;
  }
  &.align-end {
    align-items: flex-end;
  }
  &.align-center {
    align-items: center;
  }
  &.align-baseline {
    align-items: baseline;
  }
  &.align-stretch {
    align-items: stretch;
  }
}

.flex-grow {
  flex-grow: 1;
}
.flex-shrink {
  flex-shrink: 0 !important;
}
.align-self-start {
  align-self: start !important;
}
.align-self-center {
  align-self: center !important;
}
