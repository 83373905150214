$primary: #5f9ea0;
$secondary: #6ac6b8;
$info: #428bca;
$warning: #ffc107;
$background-default: #ffffff;
$background-paper: #ffffff;

$text-primary: #334455;
$text-secondary: #a9b2bb;

$white: #ffffff;
$gray: #f2f3f5;

$radii: 8px;

$border: rgba($text-secondary, 0.3);