.plan-card {
  position: relative;

  p { 
    color: $white !important; font-size: 32px; letter-spacing: 1px; position: relative; text-align: center;

    &:before { 
      @include transition(0.2s); content: ''; width: 0; height: 4px; background: $secondary;
      position: absolute; bottom: -8px; left: 50%; @include transform(translateX(-50%));
    }
  }

  &:hover { 
    @include transform(scale(1.05) !important);

    p:before { width: 100%; }
  }
}

.plan-radar {}
.plan-icons {
  a {
    @include transition(0.2s); padding: 8px; margin: 4px 0; border-radius: $radii;

    &.disable { cursor: default; opacity: 0.3; background: $gray; cursor: not-allowed; }
    &:not(.disable):hover { background: $gray; }
  }
}
.plan-icon { width: 64px; height: 64px; }